<header>
  <div class="logo-container">
    <div class="container">
      <div class="title-container">
        <div class="inner-lines-container">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>

        <div class="header-wrapper">
          <h1>{{ title }}</h1>
        </div>
      </div>

      <div class="line"></div>
      <div class="line"></div>
    </div>

    <div class="icon-container">
      <mat-icon class="nav-icon" [svgIcon]="'icons:' + svgIcon"></mat-icon>
    </div>
  </div>

  <div class="line"></div>
  <div class="line"></div>

  <app-breadcrumb></app-breadcrumb>

  <ng-content></ng-content>
</header>
