import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CardBackgroundDirective } from './pipes/card-background/card-background.directive';
import { CardNoPipe } from './pipes/card-no.pipe';
import { GetLogoBasedOnAuthor } from '../../../pages/dynamic-page/dynamic-page.pipe';
import { SharedPipesModule } from '../../../pipes/shared-pipes.module';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardBackgroundDirective,
    CardNoPipe,
    CommonModule,
    GetLogoBasedOnAuthor,
    RouterModule,
    SharedPipesModule,
  ]
})
export class CardComponent {
  @Input({ required: true }) protected readonly imgName: string;
  @Input() protected readonly title: string;
  @Input() protected readonly altLink: string;
  @Input() protected readonly url: string;
}
