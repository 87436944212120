import { Pipe, PipeTransform } from '@angular/core';
import { DynamicPage, DynamicPageParagraphContent } from '../../services/dynamic-page/dynamic-page.model';

@Pipe({
  name: 'paragraphCenter',
  standalone: true,
})
export class ParagraphCenterPipe implements PipeTransform {
  transform(paragraph: DynamicPageParagraphContent[]): DynamicPageParagraphContent[] {
    paragraph[0].center = true;
    return paragraph;
  }
}

@Pipe({
  name: 'isPredefinedAuthor',
  standalone: true,
})
export class IsPredefinedAuthor implements PipeTransform {
  transform(page: DynamicPage): boolean {
    return page.author === 'Dave Hunt'
      || page.author === 'T. A. MacMahon'
      || page.author === 'Lee Brainard'
      || page.author === 'Lighthouse Trails Publishing'
      || page.author === 'Lighthouse Trails';
  }
}

@Pipe({
  name: 'getLogoBasedOnAuthor',
  standalone: true,
})
export class GetLogoBasedOnAuthor implements PipeTransform {
  transform(page: DynamicPage | string): string {
    const _author = (page as DynamicPage)?.author || page;
    switch (_author) {
      case 'Dave Hunt': return 'logo/tbc-logo.webp';
      case 'T. A. MacMahon': return 'logo/tbc-logo.webp';
      case 'Lee Brainard': return 'logo/soothkeep-logo.webp';
      case 'Lighthouse Trails Publishing': return 'logo/lighthouse-trails-logo.webp';
      case 'Lighthouse Trails': return 'logo/lighthouse-trails-logo.webp';
      case 'Apologetyka Biblijna': return 'logo/apologetyka-biblijna-logo.webp';
    }
  }
}
