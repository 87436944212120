
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { BreadcrumbComponent } from '../../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    MatIconModule
],
})
export class TableHeaderComponent {
  @Input({ required: true }) protected readonly svgIcon: string;
  @Input({ required: true }) protected readonly title: string;
}
