@if (!url) {
  <a class="card" [routerLink]="(altLink || title) | routerLink | plEn">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}

@if (url) {
  <a class="card" [href]="url" target="_blank">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}

<ng-template #content>
  <div class="card-cover" [appCardBackground]="imgName">
    @if (title) {
      <span class="card-text">
        {{ title }}
      </span>
    }
  </div>
</ng-template>
