import { AfterViewInit, ChangeDetectorRef, Directive, HostBinding, inject, Input } from '@angular/core';

@Directive({
  selector: '[appCardBackground]',
  standalone: true,
})
export class CardBackgroundDirective implements AfterViewInit {
  @Input({ 'alias': 'appCardBackground', required: true }) protected readonly url: string;

  @HostBinding('style.backgroundImage') protected backgroundImage: string;

  private readonly cdr = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    this.loadImage();
  }

  private loadImage(): void {
    this.backgroundImage = `url(/assets/img/${this.url})`;
    this.cdr.detectChanges();
  }
}
